@font-face {
  font-family: "Recless Medium";
  src: url("./font/Reckless-Medium.otf");
  src: url("./font/Reckless-Medium.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,500;0,600;0,700;1,600;1,700&display=swap");

:root {
  --gray: #f2f2ff;
  --gray-light: #f2f2f2;
  --gray-dark-color: #f5f5f5;
  --main-font: "Roboto Flex", sans-serif;
  --secondary-font: "Recless Medium";
  --pubilc-sans-font: "Public Sans", sans-serif;
  --mid-green: #56873f;
  --dark-green: #284719;
  --light-green: #cdffb5;
  --lime-green: #3FCE3C;
}

html body {
  margin: 0;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: var(--gray-light) !important;
  letter-spacing: -0.52px;
}

html body.calculator-page {
  background-color: transparent !important;
}

.light-gray {
  background-color: #fbfbfb;
}

.mt-30 {
  margin-top: 30px;
}

.fs-medium {
  font-size: 15px;
}

.bg-gray {
  background-color: #f2f2f2;
}

.bg-light-green {
  background-color: #ECF7EE;
}

.text-black {
  color: #000;
}

img {
  max-width: 100%;
}

p {
  line-height: 18px;
}

.font-roboto {
  font-family: var(--main-font);
}

.font-reckless {
  font-family: var(--secondary-font);
}

.font-public {
  font-family: var(--pubilc-sans-font);
}

.dark-green {
  color: var(--dark-green);
}

.light-green {
  color: var(--mid-green);
}

.extra-dark-green {
  color: #012801 !important;
}

.lime-green {
  color: #3FCE3C !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.display-inline {
  display: inline;
}

.bg-gray-light {
  background-color: var(--gray);
}

.float-right {
  float: right;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.px-60 {
  padding-left: 40px;
  padding-right: 40px;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #000;
}

.h-100vh {
  height: 100vh;
}

.bg-light-gray {
  background: #f2f2f2;
}

.form-control {
  outline: none;
  box-shadow: none !important;
  font-size: 14px !important;
  padding: 5px 15px !important;
}

.signup-form {
  background-color: var(--dark-green);
  color: #cdffb5;
  padding: 100px 0;
}

.designer-signup-form {
  background-color: #cdffb5;
  color: var(--dark-green);
  padding: 100px 0;
}

.form-group {
  margin-bottom: 15px;
}

.signup-content {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  font-family: var(--pubilc-sans-font) !important;
}

.signup-form h2,
.form-heading h2 {
  font-size: 88px;
  font-weight: 500;
  line-height: 90px;
  font-family: var(--secondary-font);
  letter-spacing: -0.48px;
}

.signup-form h2 .font-public,
.form-heading h2 span.font-public {
  letter-spacing: -7px;
}

.form-inner {
  width: 525px;
  border: solid 0.71px #cdffb5;
  border-radius: 2.82px;
  padding: 40px 45px;
  letter-spacing: normal;
}

.designer-signup-form .form-inner {
  border: solid 0.71px var(--dark-green);
}

.form-inner label {
  margin-bottom: 8px;
  display: block;
  font-weight: 700;
}

.form-inner input::placeholder {
  color: var(--mid-green);
  font-weight: 500;
}

.designer-signup-form .form-inner label {
  color: var(--dark-green);
}

.form_control {
  display: block;
  width: -webkit-fill-available;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  line-height: 1.5;
  color: var(--light-green);
  background-color: transparent;
  border: 0.71px solid #cdffb5;
  border-radius: 2.82px;
  outline: none;
  font-weight: 500;
  height: 38px;
  width: 100%;
}

.designer-signup-form .form_control {
  border-color: var(--dark-green);
  color: var(--dark-green);
}

.submit-btn {
  display: block;
  padding: 14px 20px;
  outline: none;
  border: solid 1px;
  width: 100%;
  font-weight: 700;
  font-size: 17px;
  margin-top: 40px;
  border-radius: 3px;
}

.signup-btn {
  background: #cdffb5;
  border-color: #cdffb5;
  color: var(--dark-green);
  transition: 0.25 ease-in-out;
}

.signup-btn:hover {
  background-color: transparent;
  transition: 0.25 ease-in-out;
  color: #cdffb5;
}

.designer-signup-form .submit-btn {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
  color: #cdffb5;
}

.designer-signup-form .submit-btn:hover {
  background-color: transparent;
  color: var(--dark-green);
}

.signup-form p {
  color: var(--mid-green);
  font-weight: 500;
}

.signup-content p.error,
p.error {
  font-size: 13px;
  margin-top: 3px;
  color: #c74c4c !important;
}

.already-register {
  text-align: right;
  margin-top: 30px;
}

.login-redirect {
  padding: 5px 15px;
  border-radius: 30px;
  border: solid 1px var(--mid-green);
  color: #cdffb5;
  font-weight: 700;
  letter-spacing: normal;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

.login-redirect:hover {
  background: #cdffb5;
  border-color: #cdffb5;
  color: var(--dark-green);
  transition: 0.2s ease-in-out;
}

.designer-signup-form .login-redirect {
  color: var(--mid-green);
}

.designer-signup-form .login-redirect:hover {
  background-color: var(--dark-green);
  color: #cdffb5;
}

.forgot-password {
  display: flex;
  align-items: center;
}

.forgot-password-text {
  text-decoration: none;
}

.forgot-password-text:hover {
  text-decoration: underline;
}

.bomo-login-logo {
  font-style: italic;
  font-family: var(--main-font);
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: -0.48px;
}

.bomo-login-logo img {
  width: 55px;
}

.login-date {
  font-size: 19px;
  letter-spacing: -0.48px;
}

.designer-signup-form .bomo-login-logo,
.designer-signup-form .login-date {
  color: var(--dark-green);
  font-weight: 700;
  letter-spacing: -0.48px;
}

.signup-form .bomo-login-logo {
  color: #cdffb5;
}

.designer-signup-form a.color-white {
  color: var(--dark-green);
}

.forgot-password h1 {
  font-size: 55px;
}

.password i {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(50%);
  cursor: pointer;
}

.form-inner label .text-danger,
.new-request-form form label .text-danger {
  top: -0.5em;
  left: 2px;
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}

.mt-150 {
  margin-top: 150px;
}

.Home-screen {
  height: 100vh;
  background-color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.Home-screen .Home-button {
  background-color: transparent;
  padding: 15px 25px;
  color: #cdffb5;
  border: solid 1px #cdffb5;
  margin: 10px 15px;
  font-size: 16px;
  letter-spacing: -0.1px;
  font-weight: 600;
  transition: 0.2s ease-in-out;
}

.Home-screen .Home-button:hover {
  background-color: #ccffb5;
  color: var(--dark-green);
  transition: 0.2s ease-in-out;
}

.sidebar {
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;
  width: 132px;
}

.overlay {
  background-color: rgb(0 0 0 / 45%);
  z-index: 99;
}

.sidebar .list-group-item.active {
  background-color: var(--gray-light);
  color: #000;
  font-weight: 700;
}

.sidebar .list-group-item.disable {
  color: #d2cece;
  cursor: default;
}

.sidebar .list-group-item.disable:hover {
  background: transparent;
}

nav {
  position: fixed;
  top: 0;
  width: calc(100% - 132px);
  z-index: 99;
}

.main-content-wraaper {
  margin-top: 70px !important;
}

.toggle-btn {
  border-top: solid 2px;
  position: relative;
  width: 22px;
  height: unset;
  display: inline-block;
  margin-bottom: 0;
  color: #000;
}

.toggle-btn:before {
  content: "";
  position: absolute;
  border-top: solid 2px;
  width: 22px;
  left: 0;
  right: 0;
  top: -7px;
}

.toggle-btn:after {
  content: "";
  position: absolute;
  border-top: solid 2px;
  width: 22px;
  left: 0;
  right: 0;
  top: 3px;
}

.sidebar a.list-group-item {
  cursor: pointer;
  font-size: 14px;
  padding: 8px 10px 8px 15px;
}

.rightside-wrapper {
  width: calc(100% - 132px);
}

.main-content-wraaper {
  color: #000;
}

.review-main-content h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
  color: #000;
}

.review-main-content h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: normal;
}

.request-date p {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.review-content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  letter-spacing: -0.48px;
}

.review-content td p {
  font-size: 13px;
}

.review-content .short0ad {
  border: solid 1px #b185f2;
  padding: 4px 8px;
  border-radius: 20px;
  color: #b185f2;
  font-weight: 600;
}

.cutomer-home-page .review-content .short0ad:hover {
  background-color: #b185f2;
  color: #fff;
}

.review-content .d-flex {
  gap: 10px;
}

/* .payment-info{
  height: 383px;
} */
.user-email {
  line-height: 20px;
}

.review-delivery a {
  background-color: #ecf7ee;
  border: solid 1px #3fce3c;
  padding: 8px 75px;
  color: #012801;
  font-weight: 700;
  float: right;
}

.review-delivery a:hover {
  background-color: #3fce3c;
  color: #000;
  border-color: #000;
}

.review-content .web-animation {
  color: #edaffe;
  border-color: #edaffe;
}

.cutomer-home-page .review-content .web-animation:hover {
  background-color: #edaffe;
  color: #fff;
}

.draft-table {
  border: solid 1px #000;
}

.draft-table tr {
  background: #f2f2f2;
}

.draft-table tr:hover {
  background: #fbfbfb;
}

.draft-table tr {
  border-color: #000;
}

.draft-table tr:last-child,
.draft-table tr:last-child td {
  border: none;
}

.draft-table table> :not(caption)>*>* {
  background-color: transparent !important;
}

.draft-table p a {
  color: #000;
}

.draft-table tr {
  border-color: #000;
}

.draft-table tr:last-child,
.draft-table tr:last-child td {
  border: none;
}

.draft-table table> :not(caption)>*>* {
  background-color: transparent !important;
}

.draft-table p a {
  color: #000;
}

.review-content table td {
  vertical-align: middle;
  padding: 13px 7px;
}

.review-content .draft-table table td {
  padding: 13px 20px;
}

.review-content .feedback-queue td {
  padding: 13px 6px;
}

.review-content .loop {
  color: #fa812a;
  border-color: #fa812a;
  background: #fff;
}

.draft-table .review-content table td {
  padding: 13px 7px;
}

.draft-table table td:first-child {
  width: 180px;
}

/* .draft-table table td:nth-child(2) {
  width: 85px;
} */
.draft-table table td:nth-child(3) {
  width: 121px;
}

.draft-table p.short0ad {
  width: 111px;
  padding: 8px 11px;
}

.review-content .brand-element {
  color: #0657eb;
  border-color: #0657eb;
}

.cutomer-home-page .review-content .brand-element:hover {
  background-color: #0657eb;
  color: #fff;
}

.review-content .ui-animation {
  color: #7c96fe;
  border-color: #7c96fe;
}

.cutomer-home-page .review-content .ui-animation:hover {
  background-color: #7c96fe;
  color: #fff;
}

.review-content .typography {
  color: #d6c10b;
  border-color: #d6c10b;
}

.cutomer-home-page .review-content .typography:hover {
  background-color: #d6c10b;
  color: #fff;
}

.review-content .transition {
  color: #f92b34;
  border-color: #f92b34;
}

.cutomer-home-page .review-content .transition:hover {
  background-color: #f92b34;
  color: #fff;
}

.review-content .logo {
  color: #6d3ff3;
  border-color: #6d3ff3;
}

.review-content .icon {
  color: #2db985;
  border-color: #2db985;
  background: rgba(45, 185, 133, 0.1);
}

.review-content .intro {
  color: #fe58ae;
  border-color: #fe58ae;
  background: rgba(109, 63, 243, 0.1);
}

.review-content .loop {
  color: #fa812a;
  border-color: #fa812a;
  background: rgba(250, 129, 42, 0.1);
}

.cutomer-home-page .review-content .loop:hover {
  background-color: #fa812a;
  color: #fff;
}

.review-content .project-assets {
  border-color: #000;
  color: #000000;
}

.review-content p.short0ad.dor {
  width: 26px;
  height: 26px;
  padding: 0;
  font-size: 8px;
  line-height: 26px;
  background: #000;
  color: #fff;
  text-align: center;
  border: none;
  font-weight: 400;
}

p.serial-number {
  background: #d2cece;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  line-height: 17px;
}

table.feedback-queue {
  border-collapse: separate;
  border-spacing: 0 10px;
  padding: 0 15px;
  position: relative;
}

table.feedback-queue1 {
  margin-top: 10px;
}

table.feedback-queue tr td {
  background-color: var(--gray);
}

.review-content .feedback-queue td p,
table.table.table-borderless.customer-active-request td p {
  font-size: 12px;
}

table.feedback-queue tr:first-child td {
  background-color: transparent;
}

table.feedback-queue tr:hover td {
  background-color: #f2f2f2 !important;
}

table.feedback-queue tr:hover td:first-child {
  background-color: #fff !important;
}

table.feedback-queue1 tr td {
  background-color: #f2f2f2 !important;
}

table.feedback-queue1 tr td:first-child {
  background-color: #fff !important;
}

.status::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #71dd37;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.c-status .active::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #71dd37 !important;
}

.c-status .inactive::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f92b34 !important;
}

.status-btn button {
  padding: 6px 30px;
  color: #fff;
  background: transparent;
  font-size: 13px;
  letter-spacing: -0.36px;
}

.status-btn .pause-btn {
  background-color: #000000;
  line-height: normal;
}

.status-btn .pause-btn:hover {
  border-color: #000;
}

.status-btn .btn.cancel-btn {
  background-color: #f82626;
  letter-spacing: -0.36px;
}

.status-btn .btn.cancel-btn:hover {
  background-color: transparent;
  color: #f82626;
  border-color: #f82626 !important;
}

.review-content .status {
  font-size: 14px;
}

.modify-subscription {
  color: var(--dark-green);
  letter-spacing: -0.48px;
}

.modify-subscription h2 {
  font-size: 40px;
  font-weight: 500;
  font-family: var(--secondary-font);
  text-align: center;
  letter-spacing: -1.52px;
}

.modify-subscription .subscription-heading {
  font-weight: 700;
  font-family: var(--main-font);
  letter-spacing: -1.52px;
}

.subscription-heading img {
  width: 110px;
  margin-left: 5px;
}

.subscription-data .savings {
  border: solid 1px var(--mid-green);
  color: var(--dark-green);
  font-weight: 700;
  font-size: 11px;
  letter-spacing: -0.48px;
  text-align: center;
  padding: 0 2px;
}

.subscription-data .subscription-count {
  font-size: 80px;
  font-weight: 700;
  margin: 0 50px;
}

.increament-content {
  justify-content: center;
  display: flex;
}

.subscription-data .increament {
  font-size: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #56873f;
}

.increament-content {
  width: 200px;
}

.plus-increment {
  left: 0;
}

.decrease {
  right: 0;
}

.subscription-total {
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-green);
  font-family: var(--secondary-font);
  letter-spacing: -0.92px;
}

.subscription-data .savings.saving-bg-color {
  background-color: var(--light-green);
  color: var(--dark-green);
  font-weight: 500;
  border-color: transparent;
}

.subscription-data .savings.saving-bg-color u {
  font-weight: 900;
}

.modify-subscription .update-btn {
  color: var(--dark-green);
  border: solid 1px var(--mid-green);
  font-size: 17px;
  letter-spacing: -0.36px;
  padding: 3px;
  width: 22%;
}

.modify-subscription .update-btn:hover {
  background-color: var(--dark-green);
  color: var(--light-green);
}

.modify-subscription {
  border: solid 1px var(--mid-green);
}

.subscription-progress {
  gap: 5px;
  text-align: center;
  font-size: 0.9em;
}

.subscription-progress .stepIndicator {
  position: relative;
  flex: 1;
  padding-bottom: 30px;
  color: #777034;
  font-weight: 500;
  letter-spacing: -0.48px;
}

.subscription-progress .stepIndicator.active {
  font-weight: 600;
  color: #777034;
}

.subscription-progress .stepIndicator::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 9;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--mid-green);
  top: 73%;
}

.subscription-progress .stepIndicator.active::before {
  background-color: var(--mid-green);
  border: 3px solid var(--mid-green);
}

.subscription-progress .stepIndicator::after {
  content: "";
  position: absolute;
  left: 56%;
  bottom: 8px;
  width: 90%;
  height: 1px;
  background-color: var(--mid-green);
}

.modify-subscription form label {
  color: #000;
  margin-bottom: 4px;
  font-size: 14px;
}

.form-inner label .text-danger,
.modify-subscription form label .text-danger {
  top: -0.5em;
  left: 2px;
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}

.modify-subscription .pay-now {
  background: var(--mid-green);
  color: #cdffb5;
  font-weight: 500;
  letter-spacing: -0.36px;
}

.modify-subscription .pay-now:hover {
  border: solid 1px var(--mid-green);
  color: var(--mid-green);
}

.hr-line {
  border: solid 1px var(--mid-green);
}

.do-payment p {
  color: var(--dark-green);
}

.modify-subscription form input,
.modify-subscription form .form-control,
.modify-subscription form select {
  border-color: #838383;
  padding: 5px 15px !important;
  font-size: 14px;
}

.modify-subscription .sub-heading {
  font-size: 15px;
  color: var(--dark-green);
}

.modify-subscription .sub-heading {
  font-size: 17px;
  color: var(--dark-green);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

.grand-total {
  font-size: 25px;
  color: #284719;
}

.subscription-progress .stepIndicator.active::after {
  background-color: var(--mid-green);
}

.subscription-progress .stepIndicator:last-child:after {
  display: none;
}

.payment-history-section {
  background: #eaeaea;
  border: solid 0.5px #a4a4a4;
  border-radius: 4px;
}

.admin-payments .payment-history-section {
  background-color: #fff;
  border-color: #fff;
}

.payment-history-section .table> :not(caption)>*>*,
.bg-gray-mid .table> :not(caption)>*>*,
.brand-table .table> :not(caption)>*>*,
.late-request .table> :not(caption)>*>* {
  background-color: transparent;
}

.payment-history-section table tr td {
  color: #606060;
  vertical-align: middle;
}

.payment-history-section table tr th {
  padding-bottom: 20px;
}

.past-request-section table tr td {
  padding: 13px 5px;
}

.designer-past-request .review-content tr td:first-child {
  border-radius: 5px 0 0 5px;
  padding-left: 20px;
  padding-right: 30px;
}

.designer-past-request.past-request-section table tr td:last-child {
  width: 200px;
  border-radius: 0px 5px 0 5px;
}

.past-request-section .review-content .short0ad {
  width: 90px;
  padding: 4px 2px;
}

.designer-past-request.past-request-section .review-content .short0ad {
  width: 105px;
}

.past-request-section .review-content .logo {
  background: rgba(6, 87, 235, 0.1);
}

.past-request-section .review-content .typography {
  background: rgba(206, 188, 24, 0.1);
}

.past-request-section .review-content .transition {
  background: rgba(249, 43, 52, 0.1);
}

.past-request-section .review-content .brand-element {
  background: rgba(6, 87, 235, 0.1);
}

.transition-bg {
  background: rgba(249, 43, 52, 0.1);
}

.member-table tr td {
  padding: 20px 10px;
  vertical-align: top;
}

td.vertical-middle {
  vertical-align: middle !important;
}

td.vertical-end {
  vertical-align: bottom !important;
}

.member-table tr td.update-btn,
.member-table tr.members td {
  vertical-align: middle;
}

.member-table tr.members td:nth-child(1) {
  width: 210px;
}

.member-table tr.members td:nth-child(2) {
  width: 170px;
}

.member-table tr.members td:nth-child(3) {
  width: 170px;
}

.member-table tr.members td:nth-child(4) {
  width: 250px;
}

/* .member-table .member-last-column{
 width:180px
} */
.member-table .members p {
  padding: 0;
}

#color1,
.color1 {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
}

#color1::-webkit-color-swatch,
.color1::-webkit-color-swatch {
  border: none;
  border-radius: 30px;
  padding: 0;
}

#color1::-webkit-color-swatch-wrapper,
.color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 30px;
  padding: 0;
}

.color1 {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.logout-popup .modal-dialog {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (1.75rem * 2));
}

.logout-popup p,
.logout-popup h5 {
  color: #000;
}

.logout-popup h5 {
  font-weight: 600;
  font-size: 19px;
}

.modal-dialog .btn.btn-dark {
  background: #000;
}

.modal-dialog .btn.btn-dark:hover {
  background-color: transparent;
  color: #000;
}

.modal-dialog .btn-light {
  background-color: transparent;
  color: #000;
}

.modal-dialog .btn.btn-light:hover {
  background-color: #000;
}

.modal-dialog button {
  font-weight: 700;
}

.modal-body button.btn-primary {
  background: transparent;
  border-color: var(--dark-green);
  color: var(--dark-green);
  font-weight: 600;
}

.modal-body button.btn-primary:hover {
  background-color: var(--dark-green);
  color: #fff !important;
}

.modal-body button.btn-secondary {
  background-color: transparent;
  border-color: #000;
  color: #000;
  font-weight: 600;
}

.modal-body button.btn-secondary:hover {
  background-color: #000 !important;
  color: #fff;
}

.modal-body button.decline-btn {
  border-color: #F92B34;
  color: #F92B34;
}

.modal-body button.decline-btn:hover {
  background-color: #F92B34;
  border-color: #F92B34;
  color: #fff;
}

.cancel-popup .modal-content {
  border: solid 5px #f82626;
}

.cancel-popup .modal-content .btn-danger {
  background-color: #f82626;
}

.cancel-popup .modal-content .btn-danger:hover {
  background-color: transparent;
  color: #f82626;
}

.suceess-popup .modal-content {
  border: solid 1px #000;
}

.new-request-form .form-control {
  border: solid 1px #000;
  padding: 5px 15px !important;
  border-radius: 4px;
  font-size: 14px;
  height: 33px;
}

.billing-form form.form .form-control,
.billing-form form.form .form-select {
  padding: 5px 15px !important;
  font-size: 14px;
  height: 33px;
}

.new-request-form textarea.form-control {
  min-height: 226px;
  padding: 20px 15px !important;
}

.request-type .request-list {
  /* flex: auto; */
  text-align: center;
}

.bg-gray-dark {
  background-color: #d0d0d0;
}

.bg-gray-mid {
  background-color: #faf5f5;
}

.add-member span.plus {
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: #d9d9d9;
  text-align: center;
  border-radius: 50%;
  border: solid 1px;
  display: inline-block;
}

.eye-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.member-table .formcontrol {
  border: none;
  background-color: #fff;
  width: 95%;
  font-size: 13px;
  outline: none;
  height: 27px;
  padding: 5px 10px;
  border-radius: 5px;
}

select.form-control {
  appearance: auto;
}

.order-completed {
  border: solid 1px #3fce3c;
  background: #ECF7EE;
}

.order-completed p {
  color: #012801;
  font-size: 14px;
}


.order-pending {
  border: solid 1px #ce723c;
  background: #c7141412;
}

.order-pending p span{
  color:  #000;
  font-size: 14px;
}

.delivery-status-section {
  background-color: #fbfbfb;
}

.signup-form input:-webkit-autofill,
.signup-form input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #284719 inset;
  -webkit-text-fill-color: var(--light-green);
}

.designer-signup-form input:-webkit-autofill,
.designer-signup-form input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #cdffb5 inset;
  -webkit-text-fill-color: var(--dark-green);
}

#MenuToggle:checked~.sidebar {
  width: 0;
  transform: translateX(-250px);
}

#MenuToggle:checked~.right {
  width: 100%;
}

.delivery-status {
  font-size: 18px;
  letter-spacing: -0.48px;
  color: #000;
  line-height: normal;
}

.download-btn button {
  background-color: #3fce3c;
  color: #000;
  border: solid 1px #000;
  font-size: 14px;
}

.order-download-btn.download-btn button {
  font-size: 12px;
}

.review-content table.request-status td,
.review-content table.request-status th {
  padding: 1px 10px;
  line-height: 20px;
  vertical-align: top;
}

.request-content:hover {
  background: transparent !important;
  transition: 0.2s ease-in-out;
}

.new-request {
  background-color: var(--gray-light);
  transition: 0.2s ease-in-out;
}

.request-content:hover .new-request {
  background-color: #000;
  color: #fff !important;
  transition: 0.2s ease-in-out;
}

.subscribers {
  border: solid 1px #000;
}

.registration-form {
  display: flex;
  align-items: center;
  /* height: 100vh; */
}

.delivery-status i {
  color: #3fce3c;
  font-size: 14px;
}

.delivery-status-section .statusbar-section {
  gap: 20px;
}

.active-request {
  background-color: #fff;
  border: solid 1px #000;
}

.delivery-status i.minus {
  color: #838383;
}

.feedback-request {
  background-color: #f2f2f2 !important;
}

.feedback-request p {
  font-size: 14px;
}

.form-row i {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.review-content table tr td .checked-circle {
  color: #3fce3c;
}

.review-content table tr td .cancel {
  color: #f92b34;
}

.review-content table tr td i {
  padding-right: 10px;
}

.review-content .monthly-revenue-section {
  flex: 0 0 27%;
  width: 27%;
  background-color: #fff;
  margin-right: 15px;
}

.review-content .designers-section {
  flex: 0 0 16%;
  width: 16%;
  background-color: #fff;
  margin-right: 15px;
}

.review-content .designers-section:last-child {
  margin-right: 0;
}

.monthly-revenue-price h2 {
  font-size: 19px;
  font-weight: 600;
  word-wrap: break-word;

}

.bar-code img {
  width: 90px;
}

ul.talented-designer {
  background-color: #ecf7ee;
  padding: 10px 10px;
  overflow-y: auto;
  height: 80px;
}

.design-list-section ul li p {
  font-size: 12px;
  position: relative;
  padding-left: 15px;
  line-height: 15px;
  font-weight: 600;
  color: #012801;
}

ul.talented-designer.designer-list ul li p {
  color: #000;
}

ul.talented-designer li i {
  color: #838383;
  position: absolute;
  left: 0;
  top: 2px;
}

ul.designer-list {
  background-color: #fbfbfb;
  height: auto;
  overflow: unset;
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

ul.designer-list li {
  width: 32%;
  display: inline-block;
}

.bg-light-green ul.designer-list {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  gap: 9px
}

.bg-light-green ul.designer-list li {
  width: 30%;
}

.bg-light-green ul.designer-list li i {
  color: #3FCE3C !important;
}

.design-list-section.review-content .bg-light-green td p,
.design-list-section .bg-light-green ul li p {
  color: #012801;
}

.design-list-section .asigned-button {
  font-size: 12px;
  font-weight: 700;
  background-color: #fff !important;
  border: solid 1px #000;
  color: #000 !important;
}

.design-list-section .bg-light-green .asigned-button {
  border-color: #3FCE3C;
}

.queue-designer {
  font-size: 12px;
  color: #3FCE3C;
  line-height: 17px;
}

.row.bg-light-green.rounded {
  align-items: center;
}

.emailed-sucussfully {
  font-size: 12px;
}

.admin-payments .form-control {
  background: var(--gray-dark-color);
  border-radius: 30px;
  border-color: #d2cece;
  border-right: none;
  padding: 8px 30px !important;
}

.admin-payments .input-group-text {
  border-radius: 0 30px 30px 0;
  border-color: #d2cece;
  background-color: var(--gray-dark-color);
}

.MuiTablePagination-root p {
  margin-bottom: 0;
}

.request-status {
  color: #f92b34;
}

.brand-table {
  padding: 20px 20px;
  margin-bottom: 20px;
}

.brand-table img {
  width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 1px #eee;
}

.add-new-brand {
  background-color: #faf5f5;
  padding: 30px 25px;
  display: flex;
  align-items: center;
}

.add-new-brand button.add-btn,
.add-brand-profile button.add-btn {
  border: solid 1px #000;
  width: 48px;
  height: 48px;
  line-height: normal;
  font-size: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-brand-profile button.add-btn {
  background: #fff;
  display: flex;
  line-height: normal;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.brand-table table td {
  padding: 3px 10px;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
}

.brand-tags-section {
  gap: 15px;
}

.date-created {
  flex: 0 0 30%;
}

.describing-tags {
  flex: 0 0 70%;
}

.input-name .react-tagsinput-input {
  margin-bottom: 0px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  font-size: 12px;
}

.bg-medium-gray .input-name .react-tagsinput-input {
  font-size: 11px;
}

.add-brand-profile {
  border: solid 1px #000;
  padding: 30px 30px;
  border-radius: 5px;
  background: #e9e6e6;
}

.add-brand-profile .input-name,
.brand-table .input-name {
  background: transparent !important;
  border: none;
  padding: 0 !important;
  font-size: 13px;
}

.add-brand-profile .input-name {
  margin-bottom: 0;
  background: #fff !important;
  border-radius: 5px;
  padding: 4px 9px !important;
  border: none;
  width: 95%;
}

.add-brand-profile .upload-zip button {
  font-size: 13px;
  display: block;
  border: none;
  background: #fff;
  padding: 4px 9px;
  border-radius: 5px;
  font-weight: 500;
  width: 95%;
  color: #777;
  text-align: left;
}

.add-brand-profile .col-lg-1.col-12 {
  padding-right: 0;
}

.add-brand-profile label {
  font-weight: 700;
  font-size: 14px;
}

.create-add-btn {
  background-color: #3fce3c;
  padding: 5px 25px;
  color: #000;
  font-size: 13px;
  border: none;
  margin: 0 0 7px;
  text-transform: uppercase;
  display: block;
  width: 72%;
  letter-spacing: -0.48px;
  border: solid 1px transparent;
}

.create-add-btn:hover {
  background-color: transparent;
  color: #3fce3c;
  border: solid 1px #3fce3c;
}

.create-add-btn.delete-btn {
  background-color: #f92b34;
  color: #fff;
  border: solid 1px #f92b34;
}

.create-add-btn.delete-btn:hover {
  color: #f92b34;
  background: transparent;
}

.brand-table .brands-add-btn {
  background-color: #f92b34;
  color: #fff;
  font-size: 13px;
  border: none;
  margin: 0 0 7px;
  text-transform: uppercase;
  padding: 5px 25px;
  border-radius: 30px;
  font-weight: 700;
  letter-spacing: -0.48px;
  width: 100%;
  border: solid 1px transparent;
}

.brand-table .brands-add-btn:hover {
  color: #f92b34;
  background: transparent;
  border: solid 1px #f92b34;
}

.update-buttons .create-add-btn {
  background-color: #3fce3c;
  color: #000;
  border: solid 1px #3fce3c;
}

.update-buttons .create-add-btn:hover {
  background-color: transparent;
  color: #3fce3c;
  border: solid 1px #3fce3c;
}

.brand-table button.new-request {
  background: #e1e1e1;
}

.brand-table button.new-request:hover {
  background-color: #000;
  color: #e1e1e1 !important;
  border: solid 1px #000;
}

.edit-buttons {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: flex-end;
}

.brand-table button.new-request {
  background: #e1e1e1;
  letter-spacing: -0.56px;
}

/* .modal .add-brand-profile button.add-btn{
  margin-left: 10px;
} */
.modal .add-brand-profile .create-add-btn {
  width: 47%;
  margin-right: 10px;
  display: inline-block;
}

.error-msg {
  font-size: 13px;
  font-weight: 700;
  color: #c74c4c !important;
}

.add-brand-profile img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
}

.add-brand-profile p {
  margin: 0;
}

.bg-medium-gray input.input-name,
.bg-medium-gray button.add-btn.border-0,
.describing-tags span.react-tagsinput-tag {
  background: #fff !important;
  border-radius: 5px;
  padding: 4px 9px !important;
  border: none;
}

.bg-medium-gray input.input-name,
.bg-medium-gray button.add-btn.border-0 {
  width: 90%;
  text-align: left;
}

.bg-medium-gray.member-table .formcontrol,
.bg-medium-gray.member-table select {
  height: 28px;
}

.table> :not(caption)>*>* {
  background: transparent !important;
}

.bg-medium-gray.member-table select {
  padding: 0 !important;
  border: none;
  border-radius: 5px;
  background: #fff;
  opacity: 1;
}

.modal .add-brand-profile p {
  font-size: 14px;
}

.designer-table p {
  margin-bottom: 0;
}

.designer-table table td:nth-child(2) {
  width: 200px;
}

.review-content table tr td button {
  font-size: 13px;
  color: #000;
}

.designer-past-request .review-content tr td button {
  font-size: 14px;
  border-color: #f2f2f2;
}

.color-light-blue {
  color: #7c96fe;
}

.late-request {
  background-color: #f92b34;
}

.late-request table tr td,
.late-request table tr td button,
.review-content.late-request .short0ad {
  color: #fff;
  font-size: 13px;
}

.late-request table tr td button,
.review-content.late-request .short0ad {
  border-color: #fff;
}

.active-request-status {
  color: #3fce3c;
}

.text-mute {
  color: #838383 !important;
}

.spinner-container {
  position: fixed !important;
}

.main-content-wraaper {
  width: 100%;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

.ml-md-auto.py-4.ms-md-auto.rightside-wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

.subscription-status .ACTIVE {
  color: #000;
}

.ACTIVE {
  color: #58de55;
}

.INACTIVE {
  color: #838383;
}

/* customer popup */
.view-as-customer-popup .modal-dialog {
  max-width: 1200px;
}

.view-as-customer-popup .modal-content {
  background-color: #f2f2f2;
}

.view-customer-content .active-request-section {
  flex: 0 0 20%;
}

.view-customer-content .active-request-section:last-child {
  flex: 0 0 40%;
}

.view-customer-content .customer-details {
  flex: 0 0 60%;
}

.view-customer-content .customer-subscription {
  flex: 0 0 40%;
}

.view-customer-content .status-btn .btn {
  font-size: 12px;
}

.view-customer-content.view-design-content .active-request-section:last-child {
  flex: 0 0 20%;
}

.view-customer-content .start-date {
  margin-top: -17px;
  font-size: 13px;
}

.view-customer-content p {
  font-size: 13px;
  margin-bottom: 0;
}

.reel-data.review-content {
  row-gap: 10px;
}

.customer-subscription .review-content {
  padding: 28px 30px !important;
}

.Toastify__progress-bar--success {
  background-color: var(--dark-green) !important;
}

.Toastify__toast--success svg {
  fill: var(--dark-green);
}

.setting-subscription-total {
  font-size: 38px;
}

.modify-subscription-total {
  font-size: 26px;
}

.setting-subscription-total .light-green {
  font-family: var(--secondary-font);
}

.spinner-container .loading-spinner {
  border-top: 10px solid #000;
}

.add-member-count {
  padding: 15px 30px;
}

.add-member-count button.add-btn,
.member-content button.add-btn {
  width: 30px;
  height: 30px;
  line-height: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-content button.add-btn {
  border: solid 1px #000;
  border-radius: 50%;
}

.brand-assets {
  color: #84a6fd;
}

.review-content .member-table p,
.review-content .member-table select {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0;
  letter-spacing: -0.48px;
}

.review-content .member-table select {
  color: #777;
}

.member-table select {
  height: 24px;
  border-radius: 5px;
}

.member-content .member-table input:-webkit-autofill,
.member-content .member-tablem input:-webkit-autofill:focus {
  font-size: 13px;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.add-brand-profile input:-webkit-autofill,
.add-brand-profile input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--gray-light) inset !important;
}

.member-table {
  border-radius: 5px;
  background: #fff;
  padding-right: 20px;
}

.member-table tr td button.bg-mid-gray {
  background-color: #3fce3c;
  border: solid 1px #3fce3c;
}

.member-table tr td button.bg-mid-gray:hover {
  background-color: transparent;
  color: #3fce3c;
  border: solid 1px #3fce3c;
}

.member-table .d-flex {
  gap: 20px;
  padding: 0;
}

.bg-medium-gray {
  background: #e9e6e6;
}

p.user-email.date span.d-block {
  line-height: 25px;
}

.new-request-section .review-main-content p {
  letter-spacing: -0.18px;
  line-height: 21px;
  font-size: 14px;
}

.new-request-form label {
  font-size: 14px;
  letter-spacing: normal;
}

.new-request-form textarea.form-control::placeholder {
  color: #adadad;
  font-weight: 400;
}

.new-request-section .review-content .short0ad {
  padding: 4px 3px;
  cursor: default;
}

.new-request-section .request-type {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.new-request-section .request-type .request-list {
  flex: 0 0 21%;
}

.new-request-section .status-btn .pause-btn {
  font-size: 16px;
  font-weight: 700;
}

.new-request-section .status-btn .pause-btn.btn-danger {
  background-color: red;
}

.uploadFile {
  width: 100%;
  background-color: white;
  border: 0.5px solid #000;
  border-radius: 5px;
  color: grey;
  font-size: 16px;
  line-height: 23px;
  overflow: hidden;
  padding: 13px 10px 12px 10px;
  position: relative;
  resize: none;
  text-align: center;
}

.uploadFile [type="file"] {
  cursor: pointer !important;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 0px;
}

span.filename i {
  color: #000;
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin-top: 2px;
  align-items: center;
  height: 100%;
}

.modify-subscription-saving .savings {
  color: var(--mid-green);
  font-weight: 500;
  width: 70%;
}

.modify-subscription-saving {
  position: relative;
  left: -2%;
}

button.deliver-now-btn {
  padding: 4px 15px;
  background-color: #3fce3c;
  letter-spacing: -0.7px;
  font-size: 14px;
  border: solid 1px #3fce3c;
  transition: 0.3s ease-in-out;
}

button.deliver-now-btn:hover {
  background-color: transparent;
  border: solid 1px #000;
  color: #000;
}

button.deliver-now-btn.rejected {
  background-color: #F92B34;
  color: #fff;
  border: solid 1px #F92B34;
}

button.deliver-now-btn.rejected:hover {
  background-color: transparent;
  color: #F92B34;
}

.designer-header button.deliver-now-btn {
  padding: 0px 18px;
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.designer-header a {
  color: #012801;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  line-height: 15px;
  border-bottom: solid 1px #012801;
}

nav p.user-email {
  font-size: 14px;
}

.header-profile-side:hover p.user-email {
  color: #6d3ff3;
}

.header-user-img,
.header-profile-side img {
  transition: 0.25s ease-in-out;
}

.header-user-profile:hover img,
.header-profile-side:hover .header-user-img {
  transform: scale(1.2);
  transition: 0.25s ease-in-out;
}

.header-request-btn button {
  font-size: 13px;
  padding: 3px 17px;
}

.request-count {
  position: absolute;
  right: 4px;
  top: -9px;
  background: #F92B34;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
}

.counter-digit {
  background-color: #f92b34;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  font-size: 11px;
}

.designer-active-request .table> :not(caption)>*>*,
.designer-request .table> :not(caption)>*>* {
  background-color: #ecf7ee !important;
}

.designer-request .table {
  border-collapse: separate;
  border-spacing: 0px 13px;
}

.designer-active-request .table-responsive {
  border: solid 1px #ecf7ee;
  transition: 0.3s ease-in-out;
}

.designer-active-request .table-responsive:hover,
.designer-request .table tr:hover {
  border: solid 1px #3fce3c;
  transition: 0.3s ease-in-out;
}

.designer-active-request .table-responsive:hover button.deliver-now-btn,
.designer-request .table-responsive tr:hover button.deliver-now-btn {
  padding: 4px 20px;
  background: transparent;
  border: solid 1px #3fce3c;
  transition: 0.3s ease-in-out;
}

.designer-active-request .status::before {
  left: 0;
  width: 5px;
  height: 5px;
}

.designer-active-request .review-content td p {
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.48px;
}

.review-content .short0ad.short-bg {
  background-color: #b185f2;
  color: #fff;
  font-size: 10px;
  padding: 2px 15px;
}

.poll-request-section p {
  font-size: 12px;
  line-height: 14px;
}

.poll-request-section p.short0ad.dor {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 7px;
  line-height: 30px;
}

.modal-content .add-brand-profile .add-btn {
  margin-top: 10px;
}

.deadline-date.status.deliver-now-btn {
  background-color: #012801;
  letter-spacing: -0.7px;
  padding: 0px 25px 0 23px;
  font-size: 12px;
  font-weight: 600;
  color: #3fce3c;
  border-radius: 30px;
}

.designer-active-request .status.deliver-now-btn::before {
  left: 12px;
}

.delivery-arrow i {
  background: #fff;
  border: solid 1px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: #000;
}

.project-assets-btn {
  background-color: var(--gray-dark-color);
  font-size: 13px;
  color: #012801;
}

.brand-assets-btn {
  color: #838383;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--gray-dark-color);
  padding: 5px 10px;
}

.brand-assets-btn.request-poll-active {
  color: #3fce3c;
}

.calculator-page .container.bg-transparent.calculator-screen {
  color: #cdffb5 !important;
}

.calculator-page .container.bg-transparent.calculator-screen .dark-green,
.container.bg-transparent.calculator-screen .light-green,
.calculator-page .container.bg-transparent.calculator-screen .subscription-data .increament,
.calculator-page .container.bg-transparent.calculator-screen .subscription-data .savings {
  color: #cdffb5;
}

.calculator-page .container.bg-transparent.calculator-screen .subscription-data .savings.saving-bg-color {
  color: #284719;
}

.calculator-page .container.bg-transparent.calculator-screen h2 {
  font-family: var(--secondary-font);
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -1.52px;
}

.calculator-page .container.bg-transparent.calculator-screen .sub-heading {
  font-family: var(--secondary-font);
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.6px;
  line-height: 20px;
}

h3.counter-number span,
.sub-heading span {
  font-size: 13px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.search-bar-heading h3.counter-number span {
  border: solid 1px #d2cece;
  color: #838383;
}

h3.counter-circle span {
  font-size: 14px;
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: transparent;
  border: solid 1px #d2cece;
  color: #838383;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
}

h3.counter-number span.late-request-cirlce {
  background-color: #F92B34;
  color: #fff;
  border-color: #f92b34;
}

button.accordion-button .late-request-section {
  display: none;
}

button.accordion-button.collapsed .late-request-section {
  display: block;
}

.accordion-header {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.accordion .accordion-item,
.review-main-content .accordion {
  background: transparent;
  border: none;
}

.accordion-item button.accordion-button {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  outline: none;
  box-shadow: none !important;
  background: transparent;
  color: #000;
  position: relative;
  display: block;
}

.accordion-button::after {
  display: none;
}

.accordion-button>span::after {
  content: "";
  position: absolute;
  background-size: 0.8rem !important;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  transform: rotate(180deg);
  right: -45px;
  top: -3px
}

.accordion-button.collapsed>span::after {
  transform: rotate(0deg);
  top: 5px;
  right: -53px;
}

.accordion-heading {
  top: -3px;
  position: relative;
}

.accordion-header p {
  font-size: 14px;
}

.designer-request-poll .modal-body,
.designer-request-poll .table> :not(caption)>*>* {
  background: #ecf7ee;
  border-radius: 5px;
}

.designer-request-poll .review-content p {
  font-size: 14px;
  color: #012801;
}

.designer-request-poll .review-content .short0ad {
  padding: 4px 16px;
}

.project-btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.project-btn .project-assets-btn {
  padding: 4px 30px;
}

.delivery-date-content span {
  color: #000;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}

.delivery-date-content h5 {
  /* color: #012801; */
  font-size: 25px;
}

.request-status tr td {
  padding-top: 0;
}

.designer-request-status tr td:last-child,
.designer-request-status tr th:last-child {
  padding-right: 0;
}

.ready-to-delivery-section h5 {
  font-size: 13px;
  line-height: 18px;
}

.ready-to-delivery-section .upload-zip-file {
  width: 72%;
}

.upload-nine-mp4 .uploadFile,
.upload-zip-file .uploadFile,
.upload-nine-mp4 .bg-green {
  /* padding: 30px 10px; */
  border-radius: 5px;
  border: solid 1px #000;
  height: 100px;
}

.upload-zip-file .uploadFile {
  background: #d2cece;
}

.ready-to-delivery-section span.filename {
  align-items: center;
  background: #cdffb5;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  line-height: 30px;
  margin: 0 auto;
  width: 30px;
}

.ready-to-delivery-section .upload-nine-mp4:hover .uploadFile {
  background-color: #ecf7ee;
}

.ready-to-delivery-section .upload-zip-file span.filename {
  background-color: #838383;
}

.ready-to-delivery-section .upload-nine-mp4:hover span.filename,
.ready-to-delivery-section .upload-zip-file:hover span.filename {
  background-color: #fff;
}

.ready-to-delivery-section .upload-zip-file:hover .uploadFile {
  background-color: #000;
}

.ready-to-delivery-section .upload-zip-file .uploadFileupload-zip-successfully,
.ready-to-delivery-section .upload-zip-file:hover .uploadFileupload-zip-successfully.uploadFile {
  background-color: #ecf7ee;
}

.ready-to-delivery-section .after-uploaded span.filename {
  background: #ECF7EE;
}

.ready-to-delivery-section .upload-zip-file .uploadFileupload-zip-successfully span.filename,
.ready-to-delivery-section .upload-nine-mp4:hover .after-uploaded span.filename {
  background-color: #3FCE3C;
}

.ready-to-delivery-section .upload-nine-mp4:hover .after-uploaded span.filename i,
.ready-to-delivery-section .upload-zip-file .uploadFileupload-zip-successfully span.filename i {
  color: #000;
}

.upload-nine-mp4 .uploadFile.bg-red,
.upload-nine-mp4:hover .uploadFile.bg-red {
  background-color: #F92B34;
  border-color: #F92B34 !important;
}

.ready-to-delivery-section .uploadFile.bg-red span.filename,
.ready-to-delivery-section .uploadFile.bg-red:hover span.filename {
  background-color: #000;
}

.ready-to-delivery-section .uploadFile.bg-red span.filename i,
.ready-to-delivery-section .uploadFile.bg-red:hover span.filename i {
  color: #fff;
  padding-right: 2px;
}

.ready-to-delivery-section .uplaod-dimension,
.ready-to-delivery-section .uplaod-dimension.four-five {
  width: 11px;
  height: 16px;
  border-radius: 2px;
}

.ready-to-delivery-section .uplaod-dimension.one-one {
  width: 12px;
  height: 12px;
}

.ready-to-delivery-section .uplaod-dimension.sixteen-nine {
  width: 16px;
  height: 12px;
}

.expand-request .review-content .short0ad {
  padding: 4px 20px;
  font-size: 13px;
}

.delivery-status-section .delivery-status button {
  font-size: 18px;
  cursor: text;
}

.expand-request .delivery-status i {
  color: #3fce3c;
  font-size: 17px;
  background: #f2f2f2;
  padding: 7px;
  border-radius: 50%;
}

.expand-request .ready-to-delivery-section span.filename {
  background: #56873f;
}

.expand-request .ready-to-delivery-section .upload-zip-file span.filename {
  background-color: #838383;
}

.progress_bar {
  display: flex;
  margin: 30px 0;
  user-select: none;
}

.progress_bar .step {
  text-align: center;
  width: 100%;
  position: relative;
}

.modal_progress .step {
  width: auto;
}

/* .modal_progress {
  align-items: center;
  justify-content: space-around;
} */
.progress_bar .step p {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 8px;
}

.progress_bar .step .deliver-status {
  height: 25px;
  width: 25px;
  border: 2px solid transparent;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.2s;
  font-weight: 500;
  font-size: 23px;
  line-height: 25px;
  z-index: 99;
  background: #f2f2f2;
}

.progress_bar .step .deliver-status.delivery-cancel i,
.delivery-status.delivery-cancel i {
  color: #f92b34;
}

.progress_bar .step .deliver-status.delivery-check i {
  color: #3fce3c;
}

.progress_bar .step .deliver-status span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.progress_bar .step {
  z-index: 9;
  position: relative;

}

.brief-content i.fa-2x {
  font-size: 13px !important;
}

.progress_bar .step:before {
  content: "";
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #d2cece;
  z-index: -1;
}

.progress_bar .step:last-child::before {
  display: none;
}

.progress_bar .step p {
  margin-bottom: 0;
}

.progress_bar .step p.brief-date {
  color: #838383;
  font-size: 10px;
  line-height: 12px;
}

.progress_bar .step p.brief-content {
  font-size: 12px;
}

.progress_bar .step:first-child .deliver-status {
  width: auto;
  height: auto;
}

.progress_bar .step .deliver-status.delivery-request-count {
  width: 90%;
}

.progress_bar .step .delivery-request-count span {
  font-size: 12px;
  width: 100%;
}

.progress_bar.approve_progress_bar .step {
  width: auto;
}

.progress_bar.approve_progress_bar .step:before,
.progress_bar.approve_progress_bar .step:after {
  display: none;
}

.hr_line {
  border-bottom: .5px solid #d2cece;
  margin-top: 5px;
  bottom: 0;
  width: 50px;
}

.progress_bar.approve_progress_bar .step-request-count {
  width: 10%;
}

.pending-request tr td:first-child {
  width: 110px;
}

.customer-all .review-content table td {
  padding: 20px 20px;
  background: #fff !important;
}

.customer-all .review-content table td:first-child {
  padding-left: 25px;
}

.customer-all table.table.table-borderless.mb-0 {
  border-collapse: separate;
  border-spacing: 0px 13px;
}

.customer-all .review-content table td:first-child,
.designer-request .table td:first-child {
  border-radius: 5px 0 0 5px;
}

.customer-all .review-content table td:last-child,
.designer-request .table td:last-child {
  border-radius: 0px 5px 5px 0;
}

.designer-request .table td.shortad p {
  width: 105px;
}

.review-content .designer-request table td {
  padding: 13px 20px;
}

.review-delvery-popup {
  border: solid 1px#3FCE3C;
}

.review-delvery-popup h4 {
  font-size: 21px;
  font-weight: 400;
}

.review-btn {
  background-color: #ecf7ee;
  border: solid 1px #012801;
  color: #012801;
  padding: 5px 27px;
  font-size: 18px;
}

.review-btn:hover {
  background-color: #012801;
  color: #cdffb5;
}

.completed-status {
  position: absolute;
  right: 0;
}

/* .modal .modal-content{
  border: none;
} */
.modal-border .modal-content {
  border: solid 1px;
  border-radius: 5px;
}

.review-submit-popup {
  width: 50%;
  border: solid 1px #012801;
  margin: 0 auto;
  background: #ecf7ee;
  border-radius: 5px;
  color: #012801;
}

.review-submit-popup button {
  color: #012801;
  border: solid 1px #012801;
}

.review-submit-popup button:hover {
  background-color: #012801;
  color: #fff;
}

.review-submit-popup.feedback-review-form button {
  color: #000;
  border: solid 1px #000;
}

.review-submit-popup.feedback-review-form button:hover {
  background-color: #000;
  color: #fff;
}

.feedback-submit-popup {
  background-color: #f4f4f4;
}

.feedback-submit-popup p {
  font-size: 15px;
}

.bg-medium-gray .member-date {
  height: 48px;
}

/* .bg-medium-gray.member-table tr.members td:nth-child(1),
.bg-medium-gray.member-table tr.members td:nth-child(2),
.bg-medium-gray.member-table tr.members td:nth-child(3),
.bg-medium-gray.member-table tr.members td:nth-child(4) {
  width: auto;
} */
.bg-medium-gray.member-table .members .d-flex p {
  width: 75%;
}

.approve-designer-popup.logout-popup.modal.show {
  background: #00000094;
}

.poll-request-content {
  border: solid 1px transparent;
}

.poll-request-content:hover {
  border-color: #000;
}

.poll-request-content .show-brief {
  padding: 4px 10px;
  background: transparent;
  border-radius: 30px;
  font-size: 11px;
}

.poll-request-content:hover .show-brief {
  background-color: #d2cece;
  color: #fff;
}

.poll-request-content img {
  transform: scale(1);
  transition: .2s ease-in-out;
}

/* .poll-request-content:hover img{
  transform: scale(1.1);
  transition: .2s ease-in-out;
} */
.poll-request-btn button {
  background-color: #fff;
  font-size: 14px;
  color: #000;
}

.poll-request-btn button:hover {
  background-color: #000;
  color: #fff;
}

.upload-file-close {
  top: -10px;
  right: -20px;
  width: 20px;
  height: 20px;
  background: #000 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.new-request-form .uploadFile:hover {
  background-color: black;
}

.new-request-form .uploadFile:hover i {
  color: #fff !important;
}

.form-group .css-13cymwt-control {
  min-height: 33px !important;
  border-color: #000;
}

.form-group .css-qbdosj-Input,
.form-group .css-19bb58m {
  margin: 0;
}

.form-group .css-b62m3t-container .css-1fdsijx-ValueContainer {
  padding: 0px 15px;
}

.form-group .css-1xc3v61-indicatorContainer {
  padding: 4px 2px 4px 8px;
  color: #000;
}

.form-group .css-1jqq78o-placeholder {
  color: #000;
  font-size: 14px;
}

.form-group .css-tj5bde-Svg {
  width: 16px;
  height: 16px;
}

.logout-popup.cancel-popup.customer-list-popup.modal.show {
  background: #000000b8;
}

.design-status {
  padding: 27px 0;
}

.rejected-popup .bg-light-white {
  background-color: #F5F5F5 !important;
  height: 130px;
}

.poll-apply-sucess {
  position: absolute;
  left: 0;
  background: #fffafaab;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9;
}

.poll-request-checked {
  background: #fff;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.poll-request-popup i {
  background-color: #000;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 25px;
  text-align: center;
  border-radius: 50%;
}

table.table-green {
  background-color: #ECF7EE;
}

.past-request-section table tr:hover td {
  background: #f2f2f2 !important;
}

.design-list-section.review-content table td {
  padding: 13px 5px;
}

.design-list-section.review-content td p {
  font-size: 12px;
  padding: 4px 0px;
}

.expand-request table tr td,
.designer-request-status tr td,
.designer-request-status tr th,
.designer-request-poll table tr td,
.designer-request-poll table tr th {
  padding: 0px 8px;
  ;
  vertical-align: top;
  color: #967575;
}

.expand-request table tr td {
  padding: 0 13px;
}

.expand-request .review-content p,
.expand-request-data p,
.order-completed-table p {
  font-size: 14px;
  color: #000;
}

.word-break {
  word-break: break-all
}

/* .cutomer-home-page .review-content p {
  font-size: 14px;
} */
.modal-body .delivery-now-bg {
  background-color: #ECF7EE;
  border: solid 1px #ECF7EE !important;
}

.ready-to-delivery-section .upload-nine-mp4 .bg-green {
  background-color: #3FCE3C !important;
}

.ready-to-delivery-section .upload-nine-mp4:hover .bg-green {
  background-color: #ecf7ee !important;
}

/* .brand-poll-circle{
  border: solid 1px #000;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.brand-poll-circle img {
  width: 32px;
  border: solid 1px #000;
  padding: 2px;
  height: 32px;
  object-fit: cover;
}

.designer-request-poll p {
  word-break: break-all;
}

.designer-header.w-50 {
  width: 57% !important;
}

.approverequest-btn button {
  padding: 3px 10px;
  background-color: #3fce3c;
  letter-spacing: -0.7px;
  font-size: 14px;
  border: solid 1px #3fce3c;
}

.approverequest-btn .request-rejected {
  background-color: #D2CECE;
  border-color: #D2CECE;
}

.delivery-status-section .delivery-status .rejected-icon {
  background: #f2f2f2;
  justify-content: center;
  width: 36px;
  border-radius: 50%;
  height: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
}

.delivery-status-section .delivery-status i {
  font-size: 24px;
  line-height: 25px
}

.approve-delivery-btn button {
  border-color: #3FCE3C;
}

.approve-delivery-btn i {
  font-size: 18px;
}

.accordion-item .review-content td p {
  font-size: 12px;
}

.accordion-item .review-content table td {
  padding: 13px 5px;
}

span.designer-name {
  position: absolute;
  top: -15px;
}

.active-deliveries-request {
  overflow: hidden;
  overflow-y: auto;
  height: 362px;
}

.feedback-request-popup i {
  font-size: 20px
}

button.upload-file-close i {
  font-size: 10px;
}

.feedback-queue-table table {
  background-color: #fff;
}

.feedback-queue-table table:hover {
  background-color: transparent;
}

.feedback-white-table table:hover {
  background-color: #fbfbfb;
}

.designer-all .review-content table tr td i {
  font-size: 17px;
}

p.sub-heading {
  font-size: 16px;
  line-height: 24px;
}

/* .brief-request-popup .modal-body{
  height: 500px;
  overflow: auto;
}    */
.fade.file-preview-popup.logout-popup.modal.show {
  background: #00000082;
}

/* .brief-request-popup .modal-body {
  height: 548px;
  overflow: auto;
} */
.sidebar a span.rounded-circle {
  font-size: 11px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: solid 1px #d2cece;
  color: #838383;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  margin-left: 7px;
}

.delivery-accepted-date,
.fs-small {
  font-size: 11px;
}

.order-page .review-content .short0ad {
  padding: 4px 19px;
}

.order-delivery-status-section {
  padding: 32px;
}

.file-preview-popup .file-preview-column {
  width: 20%;
}

.order-page .delivery-date span {
  font-weight: 400;
}

.file-preview-column video {
  max-width: 100%;
}

@media (min-width: 576px) {
  .main-content-wraaper {
    max-width: 540px;
  }


}


@media (min-width: 768px) {
  .main-content-wraaper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .design-list-section .col-lg-2 {
    width: 15%;
  }

  .design-list-section .bg-light-green .col-lg-2 {
    width: 24.3%;
    align-self: center;
  }

  .design-list-section .col-lg-3 {
    width: 26.2%;
  }

  .design-list-section .bg-light-green .col-lg-3 {
    width: 16.9%;
    padding-left: 0;
    padding-right: 10px;
  }

  .main-content-wraaper {
    max-width: 960px;
  }

  .setting-card-info .col-lg-6,
  .designer-active-request-section .col-lg-6 {
    width: 48.3%;
    margin-right: 1.7%;
  }

  .brief-request-popup .modal-lg {
    --bs-modal-width: 890px;
  }

  .setting-card-info {
    padding: 0 10px;
  }
}

@media (min-width: 1200px) {
  .main-content-wraaper {
    max-width: 1140px;
  }

  .review-content .feedback-queue td {
    padding: 13px 5px;
  }
}

@media (min-width: 1400px) {
  .main-content-wraaper {
    max-width: 1320px;
  }

  .review-content .feedback-queue td {
    padding: 13px 8px;
  }
}

.design-list-section .review-content table td {
  padding: 13px 7px;
}

.past-request-section table tr td select,
.feedback-queue tr td select {
  text-align: center;
  width: 16px;
  border-radius: 50%;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background: #f2f2f2;
}

.past-request-section table tr td p {
  font-size: 12px;
}

.past-request-section table tr td:first-child {
  width: 90px;
}

.past-request-section table tr td:nth-child(2) {
  width: 85px;
}

.past-request-section table tr td:nth-child(3) {
  width: 65px;
}

.past-request-section table tr td:nth-child(4) {
  width: 130px;
}

.customer-active-request tr:hover,
.customer-active-request tr:hover td {
  background-color: #f7f6f6;
}

.customer-active-request tr:hover td:nth-child(1) {
  border-radius: 20px 0 0 20px;
}

.customer-active-request tr:hover td:last-child {
  border-radius: 0 20px 20px 0;
}

.delivery-now-popup i {
  font-size: 35px;
  color: #072801;
}

.delivery-now-popup .delivery-now-btn {
  background-color: #edf8ef;
}

.delivery-now-popup .delivery-now-btn-dark {
  background-color: #072801
}

.delivery-now-popup .btn {

  font-size: 14px;
  border: solid 1px #012801;
}

.delivery-now-popup .delivery-now-btn .btn {
  background-color: #072801;
  color: #fff;
}

.delivery-now-popup .btn {
  background-color: #072801 !important;
  color: #fff !important;
}

.delivery-now-popup .delivery-now-btn-dark .btn {
  background-color: #3FCE3C !important;
  color: #012801 !important;
}

.after-uploaded .h6 {
  font-size: 12px;
}

.delivery-submit-popup .modal-content {
  border: none;
}

.delivery-submit-popup .modal-body .modal_inner_cnt {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}








/*=========me==========*/
.column_seven.mobile_txt {
  display: none;
}

/* .signup-form{
  height: 100vh;
} */








@media (max-width: 1900px) {
  /* .registration-form {
    height: auto;
  } */
}

@media (min-width:1400px) {
  .signup-form {
    height: 100vh;
  }

  .registration-form {
    height: 100vh;
  }
}


@media (max-width: 1400px) {
  .customer-all .review-content table td {
    padding: 20px 10px;
  }

  .review-content table tr td button,
  .customer-all .review-content table td p {
    font-size: 12px;
  }
}

@media (max-width: 1300px) {

  .ml-md-auto.py-4.ms-md-auto.rightside-wrapper,
  nav.px-60 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .status-btn button {
    padding: 6px 20px;
  }

  .billing-form label,
  .billing-form input.form-control,
  .billing-form select {
    font-size: 14px;
  }

  .billing-form .px-60 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .new-request-section .review-content .short0ad {
    padding: 4px 2px;
    font-size: 11px;
  }

  .modify-subscription-saving .savings {
    width: 85%;
  }

  .view-as-customer-popup .modal-dialog {
    max-width: 1100px;
  }

}

/* @media screen and (min-device-width: 1200px) and (max-device-width: 1399px) { 
  .registration-form {
    height: 100vh; 
}
} */


@media (max-width: 1200px) {

  .ml-md-auto.py-4.ms-md-auto.rightside-wrapper,
  nav.px-60 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .status-btn button {
    padding: 6px 18px;
    font-size: 12px;
  }

  .add-brand-profile,
  .brand-table {
    padding: 30px 25px;
    margin-top: 10px;
  }

  .view-as-customer-popup .modal-dialog {
    max-width: 75%;
  }

  .view-customer-content .active-request-section,
  .view-customer-content .active-request-section:last-child {
    flex: 0 0 49%;
  }

  .view-customer-content .customer-details,
  .view-customer-content .customer-subscription {
    flex: 0 0 100%;
  }

  .Draft_inner_customer_wrapper .td_inner_wrap_status .table_inner_row .column_wrapper {
    width: 30%;
  }

  .Draft_inner_customer_wrapper .td_inner_wrap_status .table_inner_row {
    flex-wrap: nowrap;
    width: 100%;
  }

  .Draft_inner_customer_wrapper .td_inner_wrap_status {
    width: 300px !important;
    padding-left: 20px;
  }

  /*me*/
  .poll_request_col_two .blue_bg_poll {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .poll_request_col_one {
    width: 100% !important;
  }

  .poll-request-section p {
    font-size: 9px;
  }

  .signup-form {
    height: 100vh;
  }

  .Specify_needs_wrapper video {
    width: 100%;
  }

  .livon_request_wraper video {
    width: 100%;
  }

}

@media (max-width: 1100px) {
  .active_rqust_wrapper .td_inner_wrap_brand {
    width: 370px;
  }

  .active_rqust_wrapper .td_inner_wrap_buttons {
    width: 220px;
  }

  .active_rqust_wrapper .td_inner_wrap_status {
    width: 120px;
  }

  .accept_request_table_main .td_inner_wrap {
    width: 100px;
  }

  .accept_request_table_main .accept_request_table_main .td_inner_wrap {
    width: 100px;
  }

  .accept_request_table_main .td_inner_wrap_status {
    width: 140px;
  }

  .accept_request_table_main .td_inner_wrap_butoons {
    width: 210px;
  }

  .livon_request_wraper .video_wrapper video {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .form-inner {
    width: 500px;
  }

  .signup-form h2,
  .form-heading h2 {
    font-size: 72px;
    line-height: 75px;
  }

  .view-customer-content .customer-details,
  .view-customer-content .customer-subscription {
    flex: 0 0 100%;
  }

  .customer-details .review-main-content {
    display: block !important;
  }

  .customer-details .review-main-content div {
    padding-bottom: 10px !important;
  }

  .view-customer-content .active-request-section,
  .view-customer-content .active-request-section:last-child,
  .view-customer-content.view-design-content .active-request-section:last-child {
    flex: 0 0 50%;
  }


  /*me*/
  .Draft_inner_customer_wrapper .td_inner_wrap_dj {
    width: 100px !important;
  }
}



@media screen and (max-width: 992px) {
  .signup-content {
    display: flex;
  }

  .form-heading {
    margin-bottom: 40px;
  }

  .px-60,
  .ml-md-auto.py-4.ms-md-auto.rightside-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .form-inner {
    width: 400px;
    padding: 20px 25px;
  }

  .modify-subscription .update-btn {
    width: auto;
  }

  .add-brand-profile .row.align-items-center {
    overflow: scroll;
    flex-wrap: nowrap;
    padding: 0 0 10px;
  }

  .progress_bar {
    display: flex;
    overflow-x: scroll;
  }

  .progress_bar .step {
    padding: 0 30px;
  }

  /*start me*/
  .Draft_inner_customer_wrapper {
    white-space: nowrap;
  }

  .Draft_inner_customer_wrapper .td_inner_wrap p.short0ad {
    width: 111px;
  }

  .brand_profile_table_wrapper,
  .brand-table {
    white-space: nowrap;
  }

  .past_request_block_wraper {
    gap: 13px !important;
  }

  .past_request_table_wraper .feedback-request {
    margin-left: 19px !important;
  }

  .logout-popup.modal button.upload-file-close {
    right: 5px;
    top: 5px;
    background: #9999 !important;
  }

  .cutomer-home-page table.table.table-borderless,
  .add_memeber_inner_wrapper table.table.table-borderless {
    white-space: nowrap;
    overflow-x: scroll;
    width: 100%;
    display: block;
  }

  .cutomer-home-page .pull-right .rounded-pill {
    padding: 10px 30px;
  }

  .brand_profile_table_wrapper .column_width_wrap {
    width: auto;
  }

  .brand_profile_table_wrapper .describing-tags .react-tagsinput-input {
    display: block;
  }

  .brand_profile_table_wrapper .describing-tags {
    padding-right: 50px;
  }

  .brand_profile_table_wrapper .column_width_wrap button {
    width: 100% !important;
  }

  .add_brand_wrapper .row_flex_wrap {
    white-space: nowrap !important;
  }
}

@media (max-width:1440px) and (min-width:900px) {
  .registration-form {
    height: 100vh;
  }
}

@media screen and (max-width: 820px) {
  .sidebar {
    width: 18rem;
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;
  }

  .sidebar.active {
    transform: translateX(0);
  }

  .rightside-wrapper {
    width: 100%;
  }

  nav {
    width: 100%;
  }

  .brand-table tr,
  tr.members {
    display: flex;
    align-items: center;
  }

  .bg-medium-gray input.input-name,
  .bg-medium-gray button.add-btn.border-0 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  nav h4 {
    font-size: 15px;
  }

  .signup-form h2 {
    font-size: 60px;
    line-height: 70px;
  }

  .designer-signup-form {
    padding: 20px 0;
  }

  .modify-subscription .update-btn {
    width: 100%;
  }

  .px-60,
  .ml-md-auto.py-4.ms-md-auto.rightside-wrapper,
  .billing-form .px-60 {
    padding-left: 10px;
    padding-right: 10px;
  }

  nav.px-60 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .review-content .feedback-queue td {
    padding: 13px 15px;
  }

  .subscription-count {
    font-size: 60px;
    line-height: 65px;
  }

  .subscription-data.w-75 {
    width: 100% !important;
  }

  .status::before {
    left: -12px;
  }

  .modify-subscription h2 {
    font-size: 33px;
    line-height: normal;
  }

  .signup-content {
    display: block;
    padding: 0px;
  }

  .form-inner {
    width: auto;
  }

  .signup-form h2,
  .form-heading h2,
  .forgot-password h1 {
    font-size: 43px;
    line-height: 50px;
  }

  .already-register,
  .text-end.mt-5 {
    padding: 0 15px;
  }

  ul.designer-list li {
    width: 33%;
  }

  .review-content .monthly-revenue-section {
    flex: 0 0 100%;
    margin: 5px 0;
    background-color: transparent;
  }

  .review-content .designers-section {
    flex: 0 0 48.5%;
    margin: 5px 0;
    background-color: transparent;
  }

  .review-content .designers-section p,
  .review-content .monthly-revenue-section p {
    font-size: 12px;
  }

  .member-table tr td:nth-child(1),
  .member-table tr td:nth-child(2),
  .member-table tr td:nth-child(4) {
    width: auto;
  }

  .view-customer-content .active-request-section,
  .view-customer-content .active-request-section:last-child,
  .view-customer-content .customer-details,
  .view-customer-content .customer-subscription,
  .view-customer-content.view-design-content .active-request-section:last-child {
    flex: 0 0 100%;
  }

  .view-as-customer-popup .modal-dialog {
    max-width: 90%;
    margin: 0 auto;
  }

  .brand-table,
  .brand-table table td {
    padding: 10px 10px;
  }

  .brand-table button {
    margin-left: 0;
  }

  .new-request-section .review-content .short0ad {
    font-size: 10px;
  }

  .modify-subscription-saving {
    position: relative;
    left: 30px;
    margin-bottom: 20px;
  }

  .ready-to-delivery-section .upload-zip-file {
    width: 100%;
  }

  .designer-header {
    display: none;
  }

  .progress_bar.approve_progress_bar .step {
    padding: 0;
  }

  .main-content-wraaper video {
    width: 100%;
  }

  p.sub-heading {
    font-size: 15px;
  }

  .member-table tr.members td:nth-child(5),
  .member-table tr.members td:nth-child(2) {
    width: 100px;
  }

  .member-table tr.members td:nth-child(3) {
    width: 125px;
  }

  .member-table tr.members td:nth-child(4) {
    width: 190px;
  }

  .file-preview-popup .file-preview-column {
    width: 50%;
  }

  .file-preview-column i {
    display: block;
  }


  /*me*/
  .column_seven.header_txt .text_wrap {
    margin: 0px 30px;
  }

  .header-profile-side {
    width: 110px;
  }

  .expand-request-data .request_wrapper_table {
    justify-content: start !important;
    margin-top: 20px;
  }

  .delievery_expected {
    margin: 20px 0px;
  }

  .poll-request-content .poll_request_col_two,
  .poll_brief_button_wrapper .status-btn {
    width: 80%;
  }

  .poll-request-content .poll_request_col_three,
  .poll_brief_button_wrapper .price_poll {
    width: 20%;
  }

  .poll_buttons_wrapper .poll_selection_column {
    width: 60%;
    margin-bottom: 0px !important;
  }

  .poll_buttons_wrapper .poll_button_column {
    width: 40%;
  }

  .poll_buttons_wrapper .poll_button_column .status-btn button {
    width: auto !important;
  }

  .poll_buttons_wrapper .poll_button_column .status-btn {
    float: right !important;
  }

  .poll_brief_button_wrapper .price_poll h5 {
    font-size: 15px;
  }

  .poll_brief_button_wrapper {
    align-items: center;
  }

  .heading_poll_request h2 {
    margin-bottom: 0px !important;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .column_reference_wrapper {
    margin: 10px 0px !important;
  }

  .brief_buttons_wrap {
    width: 50% !important;
  }

  .signup-form {
    height: 100vh;
  }

  .designer-signup-form {
    height: 100vh;
  }

  .signup-form h2 .font-public,
  .form-heading h2 span.font-public {
    letter-spacing: 0px;
  }

  .signup-form h2,
  .form-heading h2 {
    letter-spacing: -0.48px;
  }

  .form-inner {
    padding: 16px 15px;
  }

  .designer-signup-form .submit-btn {
    margin-top: 10px;
  }

  .review-main-content .reel_content_wrapper {
    gap: 2px !important;
  }

  .review-main-content .reel_content_wrapper .text_inner_wrapper p {
    margin-top: 8px;
  }

  .reel_box_wrap .word-break {
    margin-bottom: 10px;
  }

  .text_spacing_reel_btm {
    margin-bottom: 5px;
  }

  .modal_delete_account .col_btn {
    width: 50%;
  }

  .payment_padding_wrapper {
    padding: 0px !important;
  }

  .payment_txt_wrapper_inner p {
    font-size: 15px;
  }

  .subscription_table_wrapper {
    white-space: nowrap;
  }

  .review-content.review_padding_wrapper {
    padding: 20px 40px !important;
  }

  .pause_govack_wrapper_btn .column_width {
    width: 100%;
  }

  .cancel_modal_inner_wrapper .column-widths {
    width: 50%;
  }

  .active-request_spacing {
    padding: 20px !IMPORTANT;
  }

  .request_status_wrapper0 {
    padding: 0px;
  }

  .past-request-section table {
    white-space: nowrap;
  }

  .logout-popup .deadline-date.status.deliver-now-btn {
    padding: 6px 25px 6px 23px;
    margin-bottom: 20px;
  }

  .review-main-content.order-page h3 {
    margin-top: 2px;
  }

  .expand-request-data .request_wrapper_align {
    justify-content: left !important;
  }

  .ready-to-delivery-section.ready-to-delivery_wrapper {
    padding: 20px !important;
  }

  .ready-to-delivery-section.ready-to-delivery_wrapper .upload-nine-mp4 {
    margin-bottom: 10px;
  }

  .ready-to-delivery_wrapper .status-btn {
    margin-top: 15px;
  }

  .buttons_width .column_width,
  .feedback_wrapper .answer_wrapper .column_width {
    width: 80%;
  }

  .buttons_width .column_widths,
  .feedback_wrapper .answer_wrapper .column_widths {
    width: 20%;
  }

  .buttons_fill_all_field .column-width {
    width: 70%;
  }

  .buttons_fill_all_field .column-widths {
    width: 30%;
  }

  .livon_request_wraper .video_wrapper video {
    width: 100%;
  }

  .livon_request_wraper .completed-status {
    position: unset;
  }

  .Specify_needs_wrapper .width_block {
    width: 80% !important;
  }

  .feedback-review-form.feedback_wrapper {
    width: 100%;
    padding: 20px !important;
  }

  .feedback_wrapper_spacing {
    padding: 0px;
  }

  .brand_profile_table_wrapper button.add-btn {
    width: auto !important;
  }

  .add_memeber_inner_wrapper .add_memeber_tb .td_width_wrapper {
    width: 100px;
  }

  .add_memeber_inner_wrapper .add_memeber_row {
    flex-wrap: nowrap;
    width: 200px;
  }

  .add_memeber_inner_wrapper .add_memeber_tb .td_width_wrapper select,
  .add_memeber_inner_wrapper .add_memeber_tb .td_width_wrapper input {
    width: 100%;
  }

  .customer_order .flex-mobile {
    justify-content: flex-start !important;
  }

  .animation_text p {
    font-size: 13px !important;
    padding: 5px 12px !important;
  }

  .review-submit-popup {
    width: 100%;
    margin: 0px !important;
  }

  .delivery-submit-popup .modal-body {
    padding: 0px 20px;
  }

  .delivery-submit-popup .modal-body {
    padding-top: 70px !important;
    padding-bottom: 100px !important;
  }

  .option_btn_wrapper .column_eight {
    width: 70%;
  }

  .option_btn_wrapper .column_four {
    width: 30%;
  }

  .delivery-now-popup .upload-file-close,
  .modal.logout-popup .upload-file-close {
    display: block !important;
  }

  .logout-popup .review-delvery-popup {
    padding: 10px !important;
  }

  .modal.logout-popup .upload-file-close {
    position: relative;
  }

  .modal.logout-popup .upload-file-close i:before {
    top: 4px;
    position: absolute;
    right: 5px;
  }

  .member-table .edit-buttons {
    width: 180px;
    padding-right: 40px !important;
  }

  .feedback-queue.feedback-queue1 tbody,
  .customer-active-request tbody {
    text-align: center;
    width: 100%;
    display: block;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.brand-add-btn {
  position: relative;
  overflow: hidden;
}

.brand-add-btn span {
  position: absolute;
  font-size: small;
  color: #3fce3c;
  bottom: 0px;
  border-top: 1px solid;
  width: 100%;
  background: #ffffff;
}

.switch input {
  display: none;
}

input:checked+.slider {
  background-color: #03ae6e;
  color: #fff;
  padding: 3px 38px 2px 14px;
}

.slider.round {
  border-radius: 34px;
}

.slider,
.slider:before {
  position: absolute;
  transition: 0.4s;
}

.slider {
  background-color: #ddd;
  bottom: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  position: relative;
  padding: 3px 14px 2px 38px;
}

input:checked+.slider .on {
  display: block;
}

.on {
  left: 35%;
}

.off {
  color: grey;
  left: 63%;
}

.off,
.on {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  transform: translate(-50%, 30%);
  white-space: nowrap;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 22px;
  left: 5px;
  width: 22px;
}

input:checked+.slider:before {
  left: unset;
  right: 6px;
}

.designer-red {
  background-color: #f92b34;
}

.designer-green {
  background-color: #71dd37;
}






/*start me alka*/
.poll_brief_content_modal button.btn {
  right: 5px;
  top: 5px;
  background: #999 !important;
}

.designer-request-poll button.upload-file-close {
  background: #999 !important;
  right: 9px;
  top: 10px;
}

.modify-subscription .stepIndicator {
  cursor: pointer;
}

.modify-subscription .stepIndicator {
  cursor: pointer;
}

.late-request .td_width_wraps {
  width: 120px;
}

.late-request .td_width_wrap {
  width: 80px;
}

.delivery-now-popup .upload-file-close,
.logout-popup .upload-file-close, .modal.delivery-now-popup .upload-file-close {
  display: none !important;
}

.approve_progress_bar .step {
  margin: 0px 10px;
}

/*swipe button*/
.rsbcSliderArrow::before {
  content: "DELIVER NOW";
  font-size: 14px;
  color: #fff;
  transform: unset !important;
  visibility: visible;
  background-color: #072801;
  padding: 9px 20px;
  border-radius: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.rsbcSliderArrow {
  transform: unset;
  visibility: hidden;
}

.ReactSwipeButton {
  border-radius: 30px;
  background-color: #edf8ef;
  height: 40px !important;
  margin-top: -32px;
}

.ReactSwipeButton .rsbcText {
  font-size: 0px;
}

.rounded.deliver_inner {
  padding-bottom: 40px !important;
}

.ReactSwipeButton .rsbContainer {
  background: #edf8ef;
}

.rsbcSlider {
  margin-left: -80% !important;
  background: transparent !important;
}

.ReactSwipeButton .rsbContainer.rsbContainerUnlocked[style="width: 400px;"] {
  margin-left: 50%;
}

.ReactSwipeButton .rsbContainerUnlocked .rsbcSlider {
  left: 106%!important;

}

.ReactSwipeButton .rsbContainer.rsbContainerUnlocked[style="width: 400px;"] .rsbcSlider span.rsbcSliderText {
  display: none;
}

.ReactSwipeButton .rsbContainer.rsbContainerUnlocked[style="width: 400px;"] .rsbcSlider span.rsbcSliderArrow {
  margin-right: 60px;
}

.ReactSwipeButton .rsbContainer .rsbcSlider span.rsbcSliderText {
  display: none;
}


span.rsbcSliderArrow {
  transform: translateY(-50%);
  white-space: nowrap;
  right: 118px;
  margin-top: -14px;
  /* display: none; */
}

.ReactSwipeButton .rsbContainer .rsbcSlider[style="background: rgb(22, 54, 45);"],
.ReactSwipeButton .rsbContainer .rsbcSlider span.rsbcSliderCircle[style="background: rgb(22, 54, 45);"] {
  background: transparent !important;
}


/*modal*/
body {
  position: relative;
}

.modal.brief-request-popup,
.modal.expand-request {
  display: flex !important;
  align-items: center !important;
}

.modal .upload-file-close {
  display: none !important;
}





@media (max-width:991px) {
  .header_wrapper .header-row {
    align-items: start !important;
  }

  .column_seven.header_txt {
    margin-left: 0px;
  }

  .header-row button.open-sidebar {
    padding: 14px 9px !important;
    line-height: 0px;
  }

  .header_wrapper.overlay {
    position: relative;
    z-index: -1;
  }

  .file-preview-popup .file-preview-column i {
    margin-top: 10px;
  }

  .accept_request_table_main .td_inner_wrap {
    width: 110px;
  }

  .accept_request_table_main .td_inner_wrap_butoons {
    width: 300px;
  }

  .accept_request_table_main .td_inner_wrap_status,
  .accept_request_table_main .td_inner_wrap_request {
    width: 130px;
  }

  .active_request_wrapper .wrapper_padding {
    padding: 20px !important;
  }

  .active_rqust_wrapper .td_inner_wrap_brand {
    width: 380px;
  }

  .active_rqust_wrapper .td_inner_wrap_buttons {
    width: 240px;
  }

  .designer-active-request.active_rqust_wrapper {
    padding: 20px !important;
  }

  .brief-request-popup .modal-dialog.modal-lg {
    max-width: 90%;
  }

  .livon_request_wraper .completed-status {
    position: unset;
  }

  .add_memeber_row {
    flex-wrap: nowrap;
  }

  .review-submit-popup {
    width: 100%;

  }






}

@media (max-width:576px) {
  .header_wrapper .header-row .column_seven {
    width: 60%;
    float: right;
  }

  .header_wrapper .header-row .column_seven span {
    font-size: 14px;
  }

  .header_wrapper .header-row {
    align-items: center !important;
  }

  /* .header_wrapper .header-row .column_seven .text-center {
    text-align: left !important;
  } */
  .header-profile-side {
    width: auto;
  }

  .column_seven.header_txt .text_wrap {
    margin: 0px;
  }

  .customer-active-request td,
  .Draft_inner_customer_wrapper td {
    padding: 5px !important;
  }

  .customer-active-request .td_inner_wrap,
  .Draft_inner_customer_wrapper .td_inner_wrap {
    width: 120px;
  }

  .draft-table p.short0ad {
    width: 100%;
  }

  .review-main-content .active-request {
    padding: 20px !important;
  }

  .brief-request-popup .review-content {
    padding: 20px 10px !important;
  }

  .poll-request-content .poll_request_col_three h5 {
    font-size: 15px;
  }

  .brief-request-popup {
    padding-left: 0px !important;
  }

  .brief-request-popup .modal-dialog.modal-lg {
    max-width: 100%;
  }

  .designer-signup-form {
    height: 100vh;
  }

  .signup-form {
    padding: 30px 0;
  }

  .signup-form .submit-btn {
    margin-top: 20px;
  }

  .payment_txt_wrapper_inner p {
    width: 150px;
  }

  .header_wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .payment-history-section h5 {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .payment-history-section .download_btn {
    font-size: 13px;
  }

  .btn_wrapper_payment {
    align-items: center;
  }

  .review-content.review_padding_wrapper {
    padding: 15px 15px 15px 15px !important;
  }

  .logout-popup .cancel_modal_inner_wrapper {
    padding: 0px 0px 26px 0px !important;
  }

  .request_wrap_both_element .text_small .short0ad {
    padding: 4px 15px;
    font-size: 11px;
  }

  .request_wrap_both_element .expand-request-data {
    gap: 0px !important;
  }

  .delivery-status-section .statusbar-section {
    margin-top: 20px;
  }

  .signup-form {
    height: auto !important;
  }

  .logout-popup .livon_request_wraper {
    padding: 0px !important;
  }

  .livon_request_wraper .margin_top {
    margin-top: 30px !important;
  }

  .Specify_needs_wrapper .width_block {
    width: 90% !important;
  }

  .add_memeber_inner_wrapper .add_memeber_row {
    flex-wrap: nowrap;
    width: 200px;
  }

  .request_wrap_both_element .delivery-status-section {
    padding: 20px !important;
  }

  .login_customer {
    height: 100vh !important;
  }

  .designer-signup-form h2 {
    font-size: 25px;
    line-height: 26px;
  }

  .forgot-password {
    height: 100vh !important;
  }

  .review_padding_wrapper .c-status h6 {
    font-size: 14px;
    padding-left: 0px !important;
  }

  .review_padding_wrapper .c-status .status-btn button {
    padding: 6px 18px;
    font-size: 12px;
  }

  .review_padding_wrapper .active.subscription-status {
    padding-left: 20px !important;
  }

  .review_padding_wrapper .active.subscription-status::before {
    left: 0;
  }

  .brand_profile_table_wrapper tr {
    align-items: start;
  }

  .new-request-section .status-btn .pause-btn {
    font-size: 13px;
  }

  .all_fill_field_box {
    padding: 10px 3px !important;
  }

  .review_padding_wrapper .c-status .inactive::before {
    left: 0px;
  }

  .review_padding_wrapper p.inactive {
    padding-left: 20px !important;
  }

  .all_fill_field_box {
    padding: 10px 0px 10px !important;
  }

  .review-delvery-popup {
    padding: 20px 14px !important;
  }

  .delievered_paid .extra-dark-green {
    font-size: 13px;
  }

  .designer-request-poll .delievered_paid {
    padding: 10px 12px !important;
  }

  .space_gap {
    gap: 10px !important;
  }

  .delivery-now-popup .deliver_inner {
    padding: 20px !important;
  }

  .deliver_inner h5 {
    font-size: 16px;
  }
  .customer_login{
    height: 100vh !important;
  }


  
}




/*end me*/

@media (max-width:400px) {
  .buttons_fill_all_field .column-widths {
    width: 40%;
  }

  .buttons_fill_all_field .column-width {
    width: 60%;
  }
  .ReactSwipeButton .rsbContainerUnlocked .rsbcSlider {
    left: 113%!important;
  }
}

@media (max-width:480px) {
  .header_wrapper .header-row .column_seven {
    display: none;
  }

  .header_wrapper .column_seven .text_wrap span {
    font-size: 13px;
    line-height: 18px !important;
    display: inline-block;
  }

  .header_profile_btn {
    margin-top: 10px;
  }

  .column_seven.mobile_txt {
    display: block !important;
  }

  .modify-subscription-saving {
    left: 0px;
  }

  .modify-subscription-saving .savings {
    width: 100%;
  }

  .delivery-submit-popup .modal-body {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}



@media (max-width:360px) {
  .new-request-section .request-type {
    gap: 8px;
  }

  .new-request-section .review-content .short0ad {
    font-size: 9px;
  }
}